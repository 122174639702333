import { ASSEMBLY_AI_CONFIG } from './config';

export const validateAudioFile = (file: File) => {
  if (!file) {
    return { valid: false, error: 'No file provided' };
  }

  if (file.size > ASSEMBLY_AI_CONFIG.upload.maxSize) {
    return {
      valid: false,
      error: `File size exceeds ${ASSEMBLY_AI_CONFIG.upload.maxSize / (1024 * 1024)}MB limit`
    };
  }

  if (!ASSEMBLY_AI_CONFIG.upload.allowedTypes.includes(file.type)) {
    return {
      valid: false,
      error: 'Unsupported file format. Please upload MP3, WAV, M4A, or FLAC files'
    };
  }

  return { valid: true };
};