export interface FAQItem {
  question: string;
  answer: string;
  category?: 'general' | 'technical' | 'pricing';
}

export const FAQ_ITEMS: FAQItem[] = [
  {
    question: "How many credits do I need to process an audio file?",
    answer: "Each audio separation uses 2 credits. You can purchase credits in various packages to suit your needs.",
    category: "pricing"
  },
  {
    question: "What type of audio files can I process?",
    answer: "Our vocal separation tool works with MP3, WAV, M4A, and FLAC audio files. For best results, we recommend using clear audio recordings with primarily two speakers.",
    category: "technical"
  },
  {
    question: "Is there a file size limit?",
    answer: "Yes, there is a 100MB file size limit per upload. We also recommend keeping audio clips under 35 minutes for optimal processing.",
    category: "technical"
  },
  {
    question: "Do credits expire?",
    answer: "No, your credits never expire. You can use them whenever you need them.",
    category: "pricing"
  },
  {
    question: "How accurate is the speaker separation?",
    answer: "Our AI-powered tool is highly accurate for clear recordings with two distinct speakers. The quality of separation depends on the original audio quality, clarity, language detected, and reducing minimal background noise. We've reached benchmarks as high as 97% accuracy on most standard audio files provided.",
    category: "technical"
  },
  {
    question: "Can I get a refund for unused credits?",
    answer: "No, we cannot offer refunds for purchased credits, whether used or unused.",
    category: "pricing"
  },
  {
    question: "What happens to my audio files after processing?",
    answer: "Your files are automatically deleted from our servers after processing is complete. We recommend saving your separated audio tracks and transcripts immediately after processing.",
    category: "technical"
  },
  {
    question: "Do you support languages other than English?",
    answer: "Yes, our system includes automatic language detection and can process audio in multiple languages. However, best results are achieved with English audio.",
    category: "technical"
  },
  {
    question: "Can I process audio with more than two speakers?",
    answer: "Our system is trained and optimized to vocally separate two speaker dialogue, for best results we recommend processing audio files with 2 speakers. Some practical use cases include podcasts, NotebookLM audio overviews, and other similar type audio.",
    category: "technical"
  }
] as const;