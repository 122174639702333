import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import App from './App';
import { isFirebaseInitialized } from './config/firebase';
import './index.css';

// Ensure Firebase is initialized before rendering
if (!isFirebaseInitialized()) {
  console.error('Firebase failed to initialize. Please check your configuration.');
  throw new Error('Firebase initialization failed');
}

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </StrictMode>
);