import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  updateProfile,
  sendEmailVerification,
  sendPasswordResetEmail
} from 'firebase/auth';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../../config/firebase';
import { AUTH_ERRORS } from './constants';
import type { AuthResult } from './types';

export const authService = {
  login: async (email: string, password: string): Promise<AuthResult> => {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      return { 
        success: true,
        warning: !user.emailVerified ? 'Please verify your email address' : undefined
      };
    } catch (error) {
      return { success: false, error: AUTH_ERRORS.DEFAULT };
    }
  },

  register: async (email: string, password: string, username: string): Promise<AuthResult> => {
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      
      await updateProfile(user, { displayName: username });
      await sendEmailVerification(user);
      
      await setDoc(doc(db, 'users', user.uid), {
        username,
        email,
        credits: 0,
        createdAt: serverTimestamp(),
        emailVerified: false
      });

      return { 
        success: true,
        message: 'Please check your email to verify your account'
      };
    } catch (error) {
      return { success: false, error: AUTH_ERRORS.DEFAULT };
    }
  },

  logout: async (): Promise<AuthResult> => {
    try {
      await firebaseSignOut(auth);
      return { success: true };
    } catch (error) {
      return { success: false, error: AUTH_ERRORS.DEFAULT };
    }
  },

  resendVerificationEmail: async (): Promise<AuthResult> => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('No authenticated user');
      
      await sendEmailVerification(user);
      return { 
        success: true,
        message: 'Verification email sent. Please check your inbox.'
      };
    } catch (error) {
      return { success: false, error: AUTH_ERRORS.DEFAULT };
    }
  },

  resetPassword: async (email: string): Promise<AuthResult> => {
    try {
      await sendPasswordResetEmail(auth, email);
      return { 
        success: true,
        message: 'Password reset email sent. Please check your inbox.'
      };
    } catch (error) {
      return { success: false, error: AUTH_ERRORS.DEFAULT };
    }
  }
};