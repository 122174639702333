export const ASSEMBLY_AI_CONFIG = {
  baseUrl: 'https://api.assemblyai.com/v2',
  endpoints: {
    upload: '/upload',
    transcript: '/transcript'
  },
  transcription: {
    maxRetries: 60,
    retryInterval: 2000, // 2 seconds
    params: {
      language_detection: true,
      speaker_labels: true,
      speakers_expected: 2,
      punctuate: true,
      format_text: true
    }
  },
  upload: {
    maxSize: 100 * 1024 * 1024, // 100MB
    allowedTypes: [
      'audio/mp3',
      'audio/wav',
      'audio/mpeg',
      'audio/m4a',
      'audio/x-m4a',
      'audio/flac'
    ]
  }
} as const;