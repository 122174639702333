import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2, Mail } from 'lucide-react';
import Logo from '../components/Logo';
import PasswordInput from '../components/PasswordInput';
import { useAuth } from '../hooks/useAuth';

const LoginPage = () => {
  const navigate = useNavigate();
  const { login, resetPassword } = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setError('');
    setSuccess('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (isForgotPassword) {
      if (!formData.email) {
        setError('Please enter your email address');
        return;
      }

      setIsLoading(true);
      const result = await resetPassword(formData.email);
      setIsLoading(false);

      if (result.success) {
        setSuccess(result.message || 'Password reset email sent. Please check your inbox.');
      } else {
        setError(result.error || 'Failed to send reset email');
      }
      return;
    }

    if (!formData.email || !formData.password) {
      setError('Please enter both email and password');
      return;
    }

    setIsLoading(true);
    const result = await login(formData.email, formData.password);
    setIsLoading(false);

    if (result.success) {
      navigate('/');
    } else {
      setError(result.error || 'Login failed');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 py-6 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto">
        <div className="bg-white rounded-xl shadow-lg p-8">
          <div className="flex items-center space-x-6">
            <div className="w-32 h-32 flex-shrink-0">
              <Logo />
            </div>
            <div>
              <h2 className="text-3xl font-bold text-gray-900">
                {isForgotPassword ? 'Reset Password' : 'Welcome Back'}
              </h2>
              <p className="mt-2 text-sm text-gray-600">
                {isForgotPassword 
                  ? 'Enter your email to receive reset instructions'
                  : 'Sign in to continue to SpeakerSplit'}
              </p>
            </div>
          </div>

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            {error && (
              <div className="p-3 bg-red-50 border border-red-200 rounded-md text-red-700 text-sm">
                {error}
              </div>
            )}

            {success && (
              <div className="p-3 bg-green-50 border border-green-200 rounded-md text-green-700 text-sm">
                {success}
              </div>
            )}

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                required
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            {!isForgotPassword && (
              <PasswordInput
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                label="Password"
                required
              />
            )}

            <div className="flex items-center justify-between">
              {!isForgotPassword && (
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-700">
                    Remember me
                  </label>
                </div>
              )}

              <button
                type="button"
                onClick={() => {
                  setIsForgotPassword(!isForgotPassword);
                  setError('');
                  setSuccess('');
                }}
                className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
              >
                {isForgotPassword ? 'Back to login' : 'Forgot password?'}
              </button>
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? (
                  <>
                    <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4" />
                    {isForgotPassword ? 'Sending...' : 'Signing in...'}
                  </>
                ) : (
                  <>
                    {isForgotPassword && <Mail className="w-4 h-4 mr-2" />}
                    {isForgotPassword ? 'Send Reset Link' : 'Sign in'}
                  </>
                )}
              </button>
            </div>

            {!isForgotPassword && (
              <div className="text-center text-sm text-gray-600">
                Don't have an account?{' '}
                <a href="#/register" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Register now
                </a>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;