import { useState, useEffect } from 'react';
import { 
  doc, 
  getDoc, 
  updateDoc, 
  onSnapshot, 
  serverTimestamp, 
  collection, 
  addDoc,
  increment,
  getFirestore,
  setDoc,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import useAuth from './useAuth';

export const useCredits = () => {
  const [credits, setCredits] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();
  const db = getFirestore();

  useEffect(() => {
    if (!user) {
      setCredits(0);
      setIsLoading(false);
      return;
    }

    // Listen for credit changes in real-time
    const unsubscribe = onSnapshot(
      doc(db, 'users', user.uid),
      (doc) => {
        if (doc.exists()) {
          setCredits(doc.data().credits || 0);
        }
        setIsLoading(false);
      },
      (error) => {
        console.error('Error listening to credits:', error);
        setIsLoading(false);
      }
    );

    // Listen for payment success events
    const paymentsUnsubscribe = onSnapshot(
      collection(db, 'customers', user.uid, 'payments'),
      async (snapshot) => {
        const changes = snapshot.docChanges();
        
        for (const change of changes) {
          if (change.type === 'added' || change.type === 'modified') {
            const payment = change.doc.data();
            
            if (payment.status === 'succeeded' && !payment.creditsProcessed) {
              const creditsToAdd = parseInt(payment.metadata?.credits || '0');

              if (creditsToAdd > 0) {
                try {
                  // Check for existing transaction
                  const existingTransactions = await getDocs(
                    query(
                      collection(db, 'creditTransactions'),
                      where('paymentId', '==', change.doc.id),
                      where('userId', '==', user.uid),
                      where('source', '==', 'purchase')
                    )
                  );

                  if (existingTransactions.empty) {
                    // Update user credits
                    const userRef = doc(db, 'users', user.uid);
                    await updateDoc(userRef, {
                      credits: increment(creditsToAdd),
                      lastUpdated: serverTimestamp()
                    });

                    // Create credit transaction with unique paymentId
                    await addDoc(collection(db, 'creditTransactions'), {
                      userId: user.uid,
                      amount: creditsToAdd,
                      type: 'add',
                      source: 'purchase',
                      paymentId: change.doc.id,
                      timestamp: serverTimestamp()
                    });

                    // Mark payment as processed
                    await updateDoc(change.doc.ref, {
                      creditsProcessed: true,
                      creditsProcessedAt: serverTimestamp()
                    });
                  }
                } catch (error) {
                  console.error('Error processing payment success:', error);
                }
              }
            }
          }
        }
      }
    );

    return () => {
      unsubscribe();
      paymentsUnsubscribe();
    };
  }, [user, db]);

  const addCredits = async (amount: number) => {
    if (!user) return { success: false, error: 'User not authenticated' };
    if (!amount || amount <= 0) return { success: false, error: 'Invalid credit amount' };

    try {
      const userRef = doc(db, 'users', user.uid);
      
      await updateDoc(userRef, {
        credits: increment(amount),
        lastUpdated: serverTimestamp()
      });

      await addDoc(collection(db, 'creditTransactions'), {
        userId: user.uid,
        amount,
        type: 'add',
        source: 'manual',
        timestamp: serverTimestamp()
      });

      return { success: true };
    } catch (error) {
      return { 
        success: false, 
        error: error instanceof Error ? error.message : 'Failed to add credits' 
      };
    }
  };

  const useCredits = async (amount: number) => {
    if (!user) return { success: false, error: 'User not authenticated' };
    if (!amount || amount <= 0) return { success: false, error: 'Invalid credit amount' };
    if (credits < amount) return { success: false, error: 'Insufficient credits' };

    try {
      const userRef = doc(db, 'users', user.uid);
      
      await updateDoc(userRef, {
        credits: increment(-amount),
        lastUpdated: serverTimestamp()
      });

      await addDoc(collection(db, 'creditTransactions'), {
        userId: user.uid,
        amount: -amount,
        type: 'deduct',
        source: 'usage',
        timestamp: serverTimestamp()
      });

      return { success: true };
    } catch (error) {
      return { 
        success: false, 
        error: error instanceof Error ? error.message : 'Failed to use credits' 
      };
    }
  };

  return { 
    credits, 
    isLoading,
    addCredits,
    useCredits
  };
};

export default useCredits;