import { client } from './client';
import { ASSEMBLY_AI_CONFIG } from './config';
import { ApiError, TimeoutError } from './errors';
import { pollUntilComplete } from './utils';
import type { TranscriptionResponse, ProcessingResult } from './types';

export const transcribeAudio = async (audioUrl: string): Promise<string> => {
  try {
    const { data: transcription } = await client.post('/transcript', {
      audio_url: audioUrl,
      ...ASSEMBLY_AI_CONFIG.transcription.params
    });

    const result = await pollUntilComplete(() => 
      client.get<TranscriptionResponse>(`/transcript/${transcription.id}`)
    );

    if (!result.text) {
      throw new ApiError('No transcription text returned', 'NO_TRANSCRIPTION');
    }

    return result.text;
  } catch (error) {
    if (error instanceof ApiError || error instanceof TimeoutError) {
      throw error;
    }
    throw new ApiError('Transcription failed', 'TRANSCRIPTION_ERROR', error);
  }
};

export const separateAudio = async (audioUrl: string): Promise<ProcessingResult> => {
  try {
    const { data: transcription } = await client.post('/transcript', {
      audio_url: audioUrl,
      ...ASSEMBLY_AI_CONFIG.transcription.params
    });

    const result = await pollUntilComplete(() => 
      client.get<TranscriptionResponse>(`/transcript/${transcription.id}`)
    );

    if (!result.utterances || !result.audio_duration) {
      throw new ApiError('Invalid transcription result', 'INVALID_RESULT');
    }

    const transcript = result.utterances
      .map(u => `[Speaker ${u.speaker}]: ${u.text}`)
      .join('\n\n');

    const speakerSegments = result.utterances.reduce((acc, utterance) => {
      const speaker = utterance.speaker;
      if (!acc[speaker]) {
        acc[speaker] = [];
      }
      acc[speaker].push({
        start: utterance.start,
        end: utterance.end,
        text: utterance.text
      });
      return acc;
    }, {} as Record<string, any[]>);

    return {
      transcript,
      language: result.language_code ? {
        code: result.language_code,
        confidence: result.language_confidence || 0
      } : undefined,
      audioSegments: Object.entries(speakerSegments).map(([speaker, segments]) => ({
        speaker,
        segments,
        duration: result.audio_duration!
      }))
    };
  } catch (error) {
    if (error instanceof ApiError || error instanceof TimeoutError) {
      throw error;
    }
    throw new ApiError('Audio separation failed', 'SEPARATION_ERROR', error);
  }
};