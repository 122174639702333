import type { AxiosResponse } from 'axios';
import { ASSEMBLY_AI_CONFIG } from './config';
import { TimeoutError } from './errors';
import type { TranscriptionResponse } from './types';

export const sleep = (ms: number): Promise<void> => 
  new Promise(resolve => setTimeout(resolve, ms));

export const pollUntilComplete = async (
  pollFn: () => Promise<AxiosResponse<TranscriptionResponse>>,
  maxRetries = ASSEMBLY_AI_CONFIG.transcription.maxRetries,
  interval = ASSEMBLY_AI_CONFIG.transcription.retryInterval
): Promise<TranscriptionResponse> => {
  let attempts = 0;

  while (attempts < maxRetries) {
    const { data: response } = await pollFn();

    if (response.status === 'completed') {
      return response;
    }

    if (response.status === 'error') {
      throw new Error(response.error || 'Processing failed');
    }

    await sleep(interval);
    attempts++;
  }

  throw new TimeoutError('Operation timed out');
};