import axios from 'axios';
import { ASSEMBLY_AI_CONFIG } from './config';
import { ApiError } from './errors';
import { validateAudioFile } from './validation';

export const uploadAudio = async (file: File): Promise<string> => {
  const validation = validateAudioFile(file);
  if (!validation.valid) {
    throw new ApiError(validation.error || 'Invalid file', 'VALIDATION_ERROR');
  }

  try {
    const apiKey = import.meta.env.VITE_ASSEMBLY_AI_API_KEY;
    if (!apiKey) {
      throw new ApiError('AssemblyAI API key not configured', 'CONFIG_ERROR');
    }

    const buffer = await file.arrayBuffer();
    
    const uploadResponse = await axios.post(
      `${ASSEMBLY_AI_CONFIG.baseUrl}/upload`,
      buffer,
      {
        headers: {
          'Authorization': apiKey,
          'Content-Type': 'application/octet-stream',
          'Transfer-Encoding': 'chunked'
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity
      }
    );

    if (!uploadResponse.data?.upload_url) {
      throw new ApiError('No upload URL received', 'UPLOAD_ERROR');
    }

    return uploadResponse.data.upload_url;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        throw new ApiError('Invalid API key', 'AUTH_ERROR');
      }
      throw new ApiError(
        error.response?.data?.error || 'Upload failed',
        'UPLOAD_ERROR',
        error
      );
    }
    throw new ApiError('Upload failed', 'UPLOAD_ERROR', error);
  }
};