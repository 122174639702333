import { doc, getDoc, setDoc, updateDoc, serverTimestamp, collection, addDoc, runTransaction } from 'firebase/firestore';
import { db } from '../../config/firebase';

export const handleEmailVerification = async (userId: string, email: string | null) => {
  try {
    await runTransaction(db, async (transaction) => {
      const userRef = doc(db, 'users', userId);
      const userDoc = await transaction.get(userRef);

      // If user doesn't exist or isn't verified yet
      if (!userDoc.exists() || !userDoc.data().emailVerified) {
        const userData = {
          email,
          username: email?.split('@')[0],
          credits: 0, // Changed from INITIAL_CREDITS to 0
          emailVerified: true,
          updatedAt: serverTimestamp()
        };

        if (!userDoc.exists()) {
          userData.createdAt = serverTimestamp();
          transaction.set(userRef, userData);
        } else {
          transaction.update(userRef, userData);
        }

        // Removed the credit transaction document creation since we no longer give signup bonus
      }
    });
  } catch (error) {
    console.error('Error handling email verification:', error);
  }
};