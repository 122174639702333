import { initializeApp, FirebaseOptions, FirebaseApp } from 'firebase/app';
import { getFirestore, Firestore } from 'firebase/firestore';
import { getAuth, Auth } from 'firebase/auth';
import { getAnalytics, Analytics } from 'firebase/analytics';

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
}

interface FirebaseServices {
  app: FirebaseApp;
  db: Firestore;
  auth: Auth;
  analytics?: Analytics;
}

// Validate environment variables
const validateConfig = (): FirebaseConfig => {
  const config = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  };

  const requiredFields: (keyof FirebaseConfig)[] = [
    'apiKey',
    'authDomain',
    'projectId',
    'storageBucket',
    'messagingSenderId',
    'appId'
  ];

  const missingFields = requiredFields.filter(field => !config[field]);
  
  if (missingFields.length > 0) {
    throw new Error(
      `Missing required Firebase configuration fields: ${missingFields.join(', ')}`
    );
  }

  return config;
};

// Initialize Firebase services
const initializeFirebase = (): FirebaseServices => {
  try {
    const config = validateConfig();
    
    // Initialize Firebase app
    const app = initializeApp(config);
    
    // Initialize Firestore
    const db = getFirestore(app);
    
    // Initialize Auth
    const auth = getAuth(app);
    
    // Initialize Analytics only if measurementId is provided
    let analytics: Analytics | undefined;
    if (config.measurementId) {
      analytics = getAnalytics(app);
    }

    return { app, db, auth, analytics };
  } catch (error) {
    console.error('Firebase initialization error:', error);
    throw error;
  }
};

// Export initialized services
export const firebase = initializeFirebase();

// Export individual services
export const { app, db, auth, analytics } = firebase;

// Helper function to check initialization status
export const isFirebaseInitialized = (): boolean => {
  try {
    return !!app && !!db && !!auth;
  } catch {
    return false;
  }
};