import React, { useEffect } from 'react';

const HelpPage: React.FC = () => {
  useEffect(() => {
    // Load JotForm script
    const script = document.createElement('script');
    script.src = 'https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Initialize JotForm handler after script loads
      if (window.jotformEmbedHandler) {
        window.jotformEmbedHandler(
          "iframe[id='JotFormIFrame-243597711839066']",
          "https://form.jotform.com/"
        );
      }
    };

    return () => {
      // Cleanup script on unmount
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-xl shadow-lg p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Help & Support</h1>
          <div className="w-full">
            <iframe
              id="JotFormIFrame-243597711839066"
              title="Customer Support"
              src="https://form.jotform.com/243597711839066"
              frameBorder="0"
              style={{
                minWidth: '100%',
                maxWidth: '100%',
                height: '539px',
                border: 'none'
              }}
              scrolling="no"
              allow="geolocation; microphone; camera; fullscreen"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpPage;