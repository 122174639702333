import axios from 'axios';
import { ASSEMBLY_AI_CONFIG } from './config';
import { ApiError } from './errors';

const validateApiKey = () => {
  const apiKey = import.meta.env.VITE_ASSEMBLY_AI_API_KEY;
  if (!apiKey) {
    throw new ApiError('AssemblyAI API key not configured', 'CONFIG_ERROR');
  }
  return apiKey;
};

export const client = axios.create({
  baseURL: ASSEMBLY_AI_CONFIG.baseUrl,
  headers: {
    'Authorization': validateApiKey(),
    'Content-Type': 'application/json'
  }
});