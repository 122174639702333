export class AssemblyAIError extends Error {
  constructor(
    message: string,
    public code: string,
    public details?: unknown
  ) {
    super(message);
    this.name = 'AssemblyAIError';
  }
}

export class ValidationError extends AssemblyAIError {
  constructor(message: string) {
    super(message, 'VALIDATION_ERROR');
    this.name = 'ValidationError';
  }
}

export class ApiError extends AssemblyAIError {
  constructor(message: string, code: string, details?: unknown) {
    super(message, code, details);
    this.name = 'ApiError';
  }
}

export class TimeoutError extends AssemblyAIError {
  constructor(message: string) {
    super(message, 'TIMEOUT_ERROR');
    this.name = 'TimeoutError';
  }
};